* {
  box-sizing: border-box;
}

.swal2-container {
  z-index: 100000000000 !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
